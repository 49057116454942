<template>
  <div class="attachments">
    <Header
      back="返回"
      title="项目任务"
      index="首页"
      titleOne="我的任务"
      beforeTitle="开始"
    />
    <div class="content">
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        label-width="80px"
        :label-position="labelPosition"
      >
        <h1>任务详情</h1>
        <ul>
          <li><span>企业名称</span>{{ this.form.company_name }}</li>
          <li><span>合同名称</span>{{ this.form.contract_name }}</li>
          <li><span>项目类型</span>{{ this.form.product_type }}</li>
          <li><span>项目名称</span>{{ this.form.product_name }}</li>
          <li><span>项目编号</span>{{ this.form.code }}</li>
        </ul>
        <el-form-item label="批次">
          <el-input
            v-model="form.order_batch"
            placeholder="请输入批次"
          ></el-input>
        </el-form-item>
        <el-form-item label="年度">
          <el-input
            v-model="form.order_year"
            placeholder="请输入年度"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目截止时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.end_time"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "top",
      form: {
        company_name: "",
        contract_name: "",
        project_type: "",
        product_name: "",
        code: "",
        order_batch: "",
        order_year: "",
        end_time: "",
        mission_id: "",
      },
      options: [],
      formRule: {},
    };
  },
  computed: {
    meber_id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    if (this.meber_id) {
      this.axios
        .get("/api/mission/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    onSubmit() {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.form.mission_id = this.meber_id;
          console.log(this.form);
          this.axios
            .post("/api/order/store", this.form)
            .then((res) => {
              console.log(res);
              this.messAge = res.message;
              this.$router.push("/mission/index");
              if (res.code == 0) {
                this.$message({
                  type: "error",
                  message: this.messAge,
                });
              } else {
                this.$message({
                  type: "success",
                  message: this.messAge,
                });
              }
            })
            .catch((res) => {
              console.log("err:", res);
            });
        } else {
          this.$message.error("");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
ul li {
  color: #909399;
  font-size: 14px;
  margin-bottom: 10px;
}

ul li span {
  width: 100px;
  display: inline-block;
  color: #cbcfe3;
}

i {
  font-style: normal;
}
</style>
